
document.addEventListener('DOMContentLoaded', (event) => {
    Menu.setHeight();
    Menu.setScrolling();
    Menu.listenOpen();
    Menu.closeMenu();

    let scroll = new SmoothScroll('a[data-scroll]', {
        header: '[data-scroll-menu]'
    });


    let doneOnce = false;
    window.addEventListener('scroll', function () {
        let isOnViewPort = isScrolledIntoView.init( document.getElementsByClassName('js-number-count')[0] );

        if (isOnViewPort && !doneOnce) {
            Counter.init(document.getElementsByClassName('js-number-count'));
            doneOnce = true;
        }
    });

    Slider.init();
    PhotoSwipePlugin.init();

    FormValidation.vivalid();
    //Selects.ChoicesJS();

    //Slider.init();

    //PhotoSwipePlugin.init();
    //PhotoSwipePlugin.presenter();

    objectFitImages();
});
