
class Slider {

    static init() {
        const sliderEl = document.getElementsByClassName('js-gallery');

        if (sliderEl.length > 0) {
            let slider = tns({
                container: sliderEl[0],
                items: 1,
                slideBy: 'page',
                autoplay: false,
                controls: false,
                responsive: {
                    576: {
                        items: 3,
                        gutter: 10
                    },
                    1280: {
                        gutter: 30
                    }
                }
            });
        }
    }
}
